$(window)
		.on(
				'load',
				function() {
					$(document)
							.ready(
									function() {
										var url = window.location.host;

										if (url.startsWith('localhost')
												|| url.startsWith('127.0.0.1')
												|| url === 'fon-e.bmf.gv.at'
												|| url === 'fon-q2.bmf.gv.at'
												|| url === 'ls1.portal.at'
												|| url === 'ls4.portal.at'
												|| url === 'fon-q.bmf.gv.at'
												|| url === 'www2.usp.gv.at') {
											url = 'https://fon-q.bmf.gv.at/html/demo/Startseite.html';
											$('.demoLink').attr("href", url)
										}
									});
				});

$(function() {
	document.loginForm.tid.focus();

	$(document.loginForm).on("submit", function(e) {
		localStorage.clear();
	});
	
	$(document.loginUIDForm).on("submit", function(e) {
		localStorage.clear();
	});

	$('.handysignatur-login-button').on('click', function(e) {
		localStorage.clear();
	});

	if (!navigator.cookieEnabled) {
		$('#content')
				.prepend(
						'<div class="alert alert-danger" role="alert">Für die Verwendung von FinanzOnline ist es unbedingt erforderlich, dass Sie das Setzen von Cookies in Ihrem Browser erlauben.</div>');
	}
});

$("#chatbot").click(
		function() {
			window.open('<fon:url value="/a/chatbot.do"/>', 'Frag Fred',
					'resizable,height=600,width=300');
		});
$("#demoLogin").click(function() {
	document.loginUIDForm.userid.value = "MaxMuster";
	document.loginUIDForm.pinuid.value = "supersecurepassword";
	setTimeout(function() {
		document.loginUIDForm.userid.value = "";
		document.loginUIDForm.pinuid.value = "";
	}, 1500)
});